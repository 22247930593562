$rhap_theme-color: rgba(255, 255, 255, 1) !default;
$rhap_background-color: rgba(0, 0, 0, 0.4) !default; // Dark background for the player
$rhap_bar-color: #535353 !default; // Dark gray for volume and progress bar
$rhap_time-color: #b3b3b3 !default; // Light gray for current time and duration text
$rhap_font-family: 'Arial', sans-serif !default;

@import 'react-h5-audio-player/src/styles.scss';

.rhap_container {
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
