/* Dark themed */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 18, 2024 */
@font-face {
  font-family: veteran_typewriterregular;
  font-style: normal;
  font-weight: 400;
  src: url(../public/veterantypewriter-webfont.woff2) format("woff2"), url(../public/veterantypewriter-webfont.woff) format("woff")
}

/*
public/depixelbreit.woff public/depixelbreit.woff2 public/depixelbreitfett.woff public/depixelbreitfett.woff2 public/depixelhalbfett.woff public/depixelhalbfett.woff2 public/depixelillegible.woff public/depixelillegible.woff2 public/depixelklein.woff public/depixelklein.woff2 public/depixelschmal.woff public/depixelschmal.woff2
*/

@font-face {
  font-family: depixelbreit;
  font-style: normal;
  font-weight: 400;
  src: url(../public/depixelbreit.woff2) format("woff2"), url(../public/depixelbreit.woff) format("woff")
}

@font-face {
  font-family: depixelbreit;
  font-style: normal;
  font-weight: 700;
  src: url(../public/depixelbreitfett.woff2) format("woff2"), url(../public/depixelbreitfett.woff) format("woff")
}

@font-face {
  font-family: depixelbreit;
  font-style: normal;
  font-weight: 600;
  src: url(../public/depixelhalbfett.woff2) format("woff2"), url(../public/depixelhalbfett.woff) format("woff")
}

@font-face {
  font-family: depixelbreit;
  font-style: normal;
  font-weight: 100;
  src: url(../public/depixelillegible.woff2) format("woff2"), url(../public/depixelillegible.woff) format("woff")
}

@font-face {
  font-family: depixelbreit;
  font-style: normal;
  font-weight: 300;
  src: url(../public/depixelklein.woff2) format("woff2"), url(../public/depixelklein.woff) format("woff")
}

@font-face {
  font-family: depixelbreit;
  font-style: normal;
  font-weight: 200;
  src: url(../public/depixelschmal.woff2) format("woff2"), url(../public/depixelschmal.woff) format("woff")
}

/* Set html and body size */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: veteran_typewriterregular;
  font-size: 16px;
  /*background-color: #1e1e1e !important;
  color: #fff;*/
}


input {
  /*color: #000;*/
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

canvas {
  max-width: 100%;
  max-height: 45vh;
  object-fit: contain;
  object-position: center;
  background-color: transparent;
}

video {
  max-width: 100%;
  max-height: 45vh;
  object-fit: cover;
  object-position: center;
  background-color: black;
}

hr {
  margin: 2em 0;
}

@media (min-width: 768px) {
  body {
    font-size: 1.2rem;
  }

  #root {
    padding: 40px;
  }
}

#root {
  margin: 0 auto;
}

button {
  margin-right: 10px;
  margin-bottom: 10px;
}